import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import { KfHeroUtility, KfAnchorLink } from "@klickinc/kf-react-components";

const MakingTheGrade = () => {
    const ctx = useContext(AppContext);

    useEffect(() => {
        ctx.setPageClass("page--transcript page--transcript-making-the-grade");
    }, []);

    return (
        <>
            <section className="transcript-section">
                <div className="container container--inner">
                    <KfHeroUtility addedClass="heading-hero-blue heading">
                        Transcript
                    </KfHeroUtility>
                    <h2 className='orange-heading'>Making a Switch: Chistopher's Treatment Journey</h2>
                    <div className="script">
                        <div className="script--body">
                            <p><span className='name'>Sima:</span> <span className='copy'>Hello, my name is Sima Perez and I am the mother of Christopher Perez. Christopher is 16 years-old, and he has Duchenne muscular dystrophy. We live in Long Beach, California. My daughter is Rebecca Perez, she is 18 years-old, and my husband Bernie. And we have two cats, Tobey and Tomás.</span></p>
                            <p><span className='name'>Sima:</span> <span className='copy'>Christopher is a very smart guy. He is going to be a senior this year. He's also working—he has a job, his very first job at the local community center and he's really excited about that.</span></p>
                            <p><span className='name'>Sima:</span> <span className='copy'>I can recall early conversations I had with our neurologist was when Christopher was still walking. Christopher was 6 to 7 years-old. The conversation came up, in the sense that we wanted to prolong his gait, we wanted to prolong him being ambulatory. And so the discussion was around drugs and steroids, to be more specific.</span></p>
                            <p><span className='name'>Sima:</span> <span className='copy'>And so we talked about the side effects, we discussed the benefits and we both thought at the time that it would be a great idea for Christopher to begin the steroid medicine, because the benefits outweigh the risks.</span></p>
                            <p><span className='name'>Sima:</span> <span className='copy'>At about 9 and a half years-old, Christopher stopped walking. And we originally thought that it would not be beneficial anymore to have him be on steroids. And so the neurologist and I had a conversation and she said, “on the contrary, we feel that Christopher needs to be on corticosteroids forever.” And I didn't understand though why. The neurologist told us that we needed to preserve Christopher's muscle and support the lung function for him for as much as possible.</span></p>
                            <p><span className='name'>Sima:</span> <span className='copy'>Christopher was on prednisone for a couple of years. We noticed some changes, and then we decided to transition and move him on to EMFLAZA.</span></p>
                            <p><span className='name'>Sima:</span> <span className='copy'>The process felt really easy and it continues to be easy. I do get, quite often, phone call reminders that it's time to schedule his order and/or delivery.</span></p>
                            <p><span className='name'>Sima:</span> <span className='copy'>What stands out the most to me is that our Case Manager, Rick, is very friendly. And he is very willing to answer any questions and he always tells me “if I don't know the answer, I'll find someone who can get you the answer,” but for the most part it's a very easy process.</span></p>
                            <p><span className='name'>Rick:</span> <span className='copy'>The first time me and Sima connected was a couple years back. Yes, she was going through—Chris was going through some issues with coverage and obtaining his refill for that month. My first impression of Sima was wow, she knows her stuff. She was aware of insurances. She kind of knew general next steps so it made it easier to solve the problem.</span></p>
                            <p><span className='name'>Rick:</span> <span className='copy'>Chris was running out of medication, so we were working through obviously figuring out what was going on and also, “hey, Chris needs medicine.” And, “how can we figure this out within a week's time?”</span></p>
                            <p><span className='name'>Rick:</span> <span className='copy'>Some of the services that I've been able to provide to Sima and a lot of our families is the basics; obviously verifying insurance, reviewing out-of-pocket costs, working very closely with the specialty pharmacies and the neurologist and providers and their staff. And then we also help connect our families with other families through our Peer Navigator Program. So yeah, there's a lot on the surface and then once patients are on therapy, you know, we're checking in and obviously continuing that relationship with our families.</span></p>
                            <p><span className='name'>Rick:</span> <span className='copy'>I can speak for the team when I say that the group here at PTC Cares, we do care about our families.</span></p>
                            <p><span className='name'>Sima:</span> <span className='copy'>Christopher wants to go to college; Christopher is excited about the future. As a mother with a son that has muscular dystrophy, it makes me very happy to know that he is looking forward to bigger and better things for him, and I am, you know, his number one supporter and I will be with him every step of the way.</span></p>
                            <p className="highlighted-copy mt-10 lowercase-highlighted-copy">To get connected with a Peer Navigator, please contact us at <KfAnchorLink url="mailto:peernavigator@ptcbio.com" linkClass="underline font-bold">peernavigator@ptcbio.com</KfAnchorLink> or <KfAnchorLink url="tel:8662825878" linkClass="underline font-bold">(866) 282-5878</KfAnchorLink>.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MakingTheGrade;

export function Head() {
    return (
        <Seo title="Video Transcript: Making a Switch - EMFLAZA® (deflazacort)" addSocialMeta={false}>
            <meta name="robots" content="noindex" />
        </Seo>
    );
};
